import ApiHelper from '@/helpers/ApiHelper.js'
import Crud from '@/store/classes/crud'
const crud = new Crud({
    module: 'order'
})

export default {
    namespaced: true,
    state: {
        filter: {
            active: null,
            id: null,
            created_at: null,
            customer: null,
            name: null,
            partner_id: null,
            status: null
        },
        gridOptions: _.cloneDeep(crud.state.gridOptions),
        status: [{
            text: 'Ожидает выполнения',
            value: 'ready'
        },
        {
            text: 'Выполнен',
            value: 'complete'
        },
        {
            text: 'Отменен',
            value: 'cancel'
        }]
    },
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions,
        async build(context, data) {
            return await ApiHelper.put(`/api/order/build`, data, {
                id: _.get(data, 'id', 0)
            });
        },
        async cancel(context, data) {
            return await ApiHelper.put(`/api/order/cancel`, data, {
                id: _.get(data, 'id', 0)
            });
        }
    }
}