import ApiHelper from '@/helpers/ApiHelper'
import Crud from '@/store/classes/crud'
const crud = new Crud({
    module: 'license_type'
})

export default {
    namespaced: true,
    state: {
        filter: {
            active: null,
            id: null,
            code: null,
            name: null
        },
        gridOptions: _.cloneDeep(crud.state.gridOptions),
        subjects: []
    },
    mutations: {
        ...crud.mutations,
        SET_SUBJECTS(state, data) {
            state.subjects = data;
        }
    },
    actions: {
        ...crud.actions,
        async fetchSubjects({commit}) {
            const { data } = await ApiHelper.get(`/api/license-type/subjects`);
            commit('SET_SUBJECTS', data);
        }
    },
    getters: {
        grades: () => {
            const grades =  _.map(_.keys(Array(11)), (i) => {
                i = _.parseInt(i);
                return {
                    value: i + 1,
                    text: (i + 1) + ' кл.'
                };   
            });

            return [
                {
                    value: '*',
                    text: 'Все классы'
                },
                ...grades
            ];
        }
    }
}