import Crud from '@/store/classes/crud'
const crud = new Crud({
    module: 'partner'
})

export default {
    namespaced: true,
    state: {
        filter: {
            active: null,
            id: null,
            name: null,
            email: null,
            inn: null,
            contacts: null
        },
        gridOptions: _.cloneDeep(crud.state.gridOptions)
    },
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions
    }
}