import Crud from '@/store/classes/crud'
const crud = new Crud({
    module: 'stats'
})

export default {
    namespaced: true,
    state: {
        filter: {
            platform: '',
            version: '',
            huid: '',
            ip: '',
            country: '',
            city: ''
        },
        gridOptions: _.cloneDeep(crud.state.gridOptions)
    },
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions
    }
}