import Crud from '@/store/classes/crud'
const crud = new Crud({
    module: 'code'
})

export default {
    namespaced: true,
    state: {
        filter: {
            active: null,
            id: null,
            value: null,
            license_type_id: null
        },
        gridOptions: _.cloneDeep(crud.state.gridOptions),
        sources: {
            order: 'Заказ'
        }
    },
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions
    }
}